import React from 'react';
import { Button, Tooltip } from 'antd';
import { FavoriteButton } from 'container/BlogContainer/blog/style';

import FeatherIcon from 'feather-icons-react';

const StarButton = ({
  isFavorite,
  handleUpdateIsFavorite,
  errorMessage,
  isFavoriteArticlesMaxLength,
}) => {
  const handleButtonClick = () => handleUpdateIsFavorite();

  const addToFavoriteDisabled = isFavoriteArticlesMaxLength && !isFavorite;

  return (
    <FavoriteButton>
      <Button
        className={`heart-button ${errorMessage && isFavorite ? 'liked' : null} ${
          addToFavoriteDisabled ? 'cursor-not-allowed' : null
        }`}
        onClick={handleButtonClick}
      >
        <Tooltip
          title={
            errorMessage
              ? isFavorite
                ? `Supprimer cet article de la page d'accueil`
                : `Ajouter cet article à la page d'accueil`
              : `Ajouter cet article à la page d'accueil`
          }
          showArrow={false}
        >
          <FeatherIcon icon="star" size={18} />
        </Tooltip>
      </Button>
    </FavoriteButton>
  );
};

export default StarButton;
