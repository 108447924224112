import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_HOST, API } from 'config/api';
import axiosService from 'services/axiosService';

export const createServiceTypes = createAsyncThunk(
  'createServiceTypes',
  async (data, { rejectWithValue }) => {
    const URL = API_HOST + API.serviceType.create;
    try {
      return await axiosService.post(URL, data);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
