import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DatePicker } from 'antd';
import TablePageHeader from 'components/Table/TablePageHeader';

import {
  setFeaturedArticlesModal,
  setFilters,
} from 'redux/slices/blog/featured-articles/featuredArticlesSlice';
import { getFeaturedArticles } from 'redux/slices/blog/featured-articles/featuredArticlesThunks';

const SpotlightBlogHeader = () => {
  const dispatch = useDispatch();

  const {
    list: { data },
    filter,
  } = useSelector(state => state.featuredArticles);

  const result = data?.num_totals_items === 1 ? ' Article ' : ' Articles ';
  const totalPerPage = (data?.current_page_number - 1) * data?.num_items_per_page + 1;
  const totalArticle = data?.num_totals_items;
  const totalShownItems = Math.min(
    totalPerPage + data?.num_items_per_page - 1,
    data?.num_totals_items
  );

  const onOpenAddArticleModal = () =>
    dispatch(setFeaturedArticlesModal({ modalRef: 'add-featured-article' }));

  const searchInputOnChange = event => {
    const search = event.target.value;
    dispatch(setFilters({ search }));
    dispatch(getFeaturedArticles({ filter: { ...filter, search }, page: 1 }));
  };

  const selectDateHandler = (_, dateString) => {
    const startDate = dateString[0] !== '' ? dateString[0] : null;
    const endDate = dateString[1] !== '' ? dateString[1] : null;
    const date = { startDate, endDate };

    dispatch(setFilters({ ...date }));
    dispatch(getFeaturedArticles({ filter: { ...filter, ...date }, page: 1 }));
  };

  return (
    <TablePageHeader
      title="Article à la une"
      result={result}
      totalPerPage={totalPerPage}
      total={totalArticle}
      totalShownItems={totalShownItems}
      showButton
      onButtonClick={onOpenAddArticleModal}
      placeholder="Recherche par titre et auteur"
      buttonText="Nouvel Article à la une"
      searchInputValue={filter.search}
      searchInputOnChange={searchInputOnChange}
      otherInputsAfterSearchInput={
        <div className="align-center-v filter_container">
          <span className="table-result filter_text">Filtrer par:</span>
          <DatePicker.RangePicker
            format="YYYY-MM-DD"
            onChange={selectDateHandler}
            placeholder={['Date de début', 'Date de fin']}
          />
        </div>
      }
    />
  );
};

export default SpotlightBlogHeader;
