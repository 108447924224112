import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form, Image, Input, Modal, Upload } from 'antd';
import RichTextEditor from 'react-rte';
import { PlusOutlined } from '@ant-design/icons';

import {
  editBlog,
  editImageBlog,
  filterBlog,
  getOneBlog,
} from 'redux/slices/blog/articles/blogAsyncThunk';
import { clearBlogData } from 'redux/slices/blog/articles/blogSlice';

const EditModal = ({ isOpenModalEdit, setIsOpenModalEdit, page, selectedBlogId }) => {
  const dispatch = useDispatch();
  const blogData = useSelector(state => state.blog?.getSingleBlog.data);

  const [form] = Form.useForm();

  const [state, setState] = useState({
    value: RichTextEditor.createEmptyValue(),
    tags: [],
  });
  const [imageUrl, setImageUrl] = useState('');
  const [imageId, setImageId] = useState('');

  const onChanges = value => {
    setState({ value });
  };

  const handleImageChange = async info => {
    const { fileList } = info;
    const formData = new FormData();
    if (fileList.length > 0) {
      const selectedFile = fileList[fileList.length - 1];

      if (selectedFile.originFileObj) {
        formData.append('file', selectedFile.originFileObj);

        dispatch(editImageBlog(formData)).then(res => {
          setImageUrl(res.payload.lien);
          setImageId(res.payload.id);
        });
      }
    }
  };

  const handleOk = async () => {
    if (imageUrl !== '') {
      const data = {
        id: selectedBlogId,
        values: {
          ...form.getFieldsValue(),
          contenuBlog: state.value.toString('html'),
          imageBlog: imageId,
        },
      };

      dispatch(editBlog(data));
    } else {
      const data = {
        id: selectedBlogId,
        values: {
          ...form.getFieldsValue(),
          contenuBlog: state.value.toString('html'),
        },
      };

      dispatch(editBlog(data));
    }

    dispatch(filterBlog({ page }));
    setIsOpenModalEdit(false);
  };

  const handleCancel = () => {
    setIsOpenModalEdit(false);
    dispatch(clearBlogData());
  };

  useEffect(() => {
    if (blogData && blogData.length > 0) {
      const firstItem = blogData[0];
      form.setFieldsValue({
        titre: firstItem?.titre,
        auteur: firstItem?.auteur,
      });
      const updatedState = {
        value: RichTextEditor.createValueFromString(firstItem.contenu, 'html'),
        tags: [],
      };
      setState(updatedState);
    }
  }, [form, blogData]);

  useEffect(() => {
    if (isOpenModalEdit && selectedBlogId) {
      dispatch(getOneBlog({ id: selectedBlogId }));
    }
  }, [dispatch, selectedBlogId, isOpenModalEdit]);

  useEffect(() => {
    if (blogData && blogData[0]) {
      setImageUrl(blogData[0].image);
    }
  }, [blogData]);

  return (
    <>
      <Modal
        title="Modifier ce blog"
        open={isOpenModalEdit}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Modifier"
        cancelText="Annuler"
        okType=""
        width="80vw"
      >
        <Form form={form}>
          <Form.Item
            name="file"
            rules={[
              { required: true, message: 'Veuillez sélectionner une image pour votre article.' },
            ]}
            className="mb-0 mr-1"
          >
            <Upload
              listType="picture-card"
              beforeUpload={() => {
                return false;
              }}
              fileList={[]}
              maxCount={1}
              onChange={handleImageChange}
              showUploadList={{
                showPreviewIcon: false,
              }}
            >
              {imageUrl ? (
                <Image src={imageUrl} preview={false} />
              ) : (
                <div>
                  <PlusOutlined />
                </div>
              )}
            </Upload>
          </Form.Item>
          <div className="d-flex full-width mb-1 mt-1">
            <Form.Item
              label="Titre"
              name="titre"
              rules={[{ required: true, message: 'Le titre est obligatoire' }]}
              className="full-width mr-1"
            >
              <Input placeholder="Titre de l'article" />
            </Form.Item>

            <Form.Item
              name="auteur"
              label="Auteur"
              rules={[
                {
                  required: true,
                  message: "Vous devez insérer le nom de l'auteur!",
                },
              ]}
              className="full-width"
            >
              <Input placeholder="L'auteur de l'article..." />
            </Form.Item>
          </div>

          <Form.Item label="Contenu de l'article" rules={[{ required: true }]}>
            {state?.value && (
              <RichTextEditor
                placeholder="Contenu de l'article..."
                value={state.value}
                onChange={onChanges}
              />
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditModal;
