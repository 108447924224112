import React from 'react';

import Input from 'antd/lib/input/Input';

import { SearchInputContainer } from './styled';

const SearchInput = ({ placeholder, value, onChange }) => {
  return (
    <SearchInputContainer>
      <Input placeholder={placeholder} value={value} onChange={onChange} />
      <i className="icon-search" />
    </SearchInputContainer>
  );
};

export default SearchInput;
