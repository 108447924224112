import React from 'react';
import { useDispatch } from 'react-redux';

import FeatherIcon from 'feather-icons-react';

import { setAdminListModal } from 'redux/slices/adminList/adminListSlice';

const AdminAction = ({ email, id }) => {
  const dispatch = useDispatch();

  const onOpenEditModal = () =>
    dispatch(setAdminListModal({ modalRef: 'add-edit-admin', editMode: true, email, id }));

  const onOpenDeleteModal = () => dispatch(setAdminListModal({ modalRef: 'delete-admin', id }));

  return (
    <div>
      <FeatherIcon
        icon="edit"
        size={15}
        className="mr-50 cursor-pointer"
        onClick={onOpenEditModal}
      />
      <FeatherIcon
        icon="trash"
        size={15}
        className="cursor-pointer"
        onClick={onOpenDeleteModal}
        color="#f46a6a"
      />
    </div>
  );
};

export default AdminAction;
