import { createAsyncThunk } from '@reduxjs/toolkit';
import { API, API_HOST } from 'config/api';
import axiosService from 'services/axiosService';

export const getAdminsList = createAsyncThunk(
  'adminsList/getAdminsList',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    const URL = API_HOST + API.adminsList.list;

    return axiosService
      .get(URL)
      .then(res => fulfillWithValue(res.data.items))
      .catch(err => rejectWithValue(err.message));
  }
);

export const addAdmin = createAsyncThunk(
  'adminsList/addAdmin',
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const URL = API_HOST + API.adminsList.add;

    return axiosService
      .post(URL, data)
      .then(res => fulfillWithValue(res.data.data))
      .catch(err => rejectWithValue(err.message));
  }
);

export const editPassword = createAsyncThunk(
  'adminsList/editPassword',
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const { id, form } = data;
    const URL = API_HOST + API.adminsList.edit(id);

    return axiosService
      .patch(URL, form)
      .then(res => fulfillWithValue(res.data.data))
      .catch(err => rejectWithValue(err.message));
  }
);

export const deleteAdmin = createAsyncThunk(
  'adminsList/deleteAdmin',
  async (id, { fulfillWithValue, rejectWithValue }) => {
    const URL = API_HOST + API.adminsList.delete(id);

    return axiosService
      .delete(URL)
      .then(() => fulfillWithValue(id))
      .catch(err => rejectWithValue(err.message));
  }
);
