import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import withAdminLayout from 'layout/withAdminLayout';
import Loader from 'components/loader';
import AdminsListPage from 'container/admin-list';
import DrivingSchoolsRequest from 'container/driving-schools/requests';
import Blog from 'container/BlogContainer/blog';
import SpotlightBlog from 'container/BlogContainer/spotlightBlog';

const DrivingSchoolsList = lazy(() => import('container/driving-schools/list'));
const StudentsList = lazy(() => import('container/students'));
const AddDrivingSchool = lazy(() => import('container/add-driving-school'));
const DemandeList = lazy(() => import('container/demande-list'));
const Settings = lazy(() => import('container/settingsPage'));

const Admin = () => {
  const { path } = useRouteMatch();
  const fallback = () => {
    return (
      <div className="spin">
        <Spin />
      </div>
    );
  };

  return (
    <>
      <Loader />
      <Switch>
        <Suspense fallback={fallback()}>
          <Route exact path={`${path}`} component={DrivingSchoolsList} />
          <Route exact path={`${path}/auto-ecoles`} component={DrivingSchoolsList} />
          <Route exact path={`${path}/élèves`} component={StudentsList} />
          <Route exact path={`${path}/ajout-auto-ecoles`} component={AddDrivingSchool} />
          <Route exact path={`${path}/edit-auto-ecoles/:id`} component={AddDrivingSchool} />
          <Route exact path={`${path}/articles`} component={Blog} />
          <Route exact path={`${path}/article-a-la-une`} component={SpotlightBlog} />
          <Route exact path={`${path}/liste-demandes`} component={DemandeList} />
          <Route exact path={`${path}/liste-admins`} component={AdminsListPage} />
          <Route exact path={`${path}/demandes-auto-ecoles`} component={DrivingSchoolsRequest} />
          <Route exact path={`${path}/parametres`} component={Settings} />
        </Suspense>
      </Switch>
    </>
  );
};

export default withAdminLayout(Admin);
