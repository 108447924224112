import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ArticleCardItem from '../card/ArticleCardItem';
import EmptyListMessage from 'components/alerts/emptyListMessage/EmptyListMessage';
import { ArticlesContainer } from 'container/BlogContainer/blog/style';
import { Pagination } from 'antd';
import { filterBlog } from 'redux/slices/blog/articles/blogAsyncThunk';

const ArticlesList = ({ page }) => {
  const dispatch = useDispatch();

  const {
    articles,
    filters: { query },
  } = useSelector(state => state.blog);
  const favoriteArticles = useSelector(state => state.blog.favoriteArticles.data);

  const emptyListMessage =
    query !== '' ? (
      <>
        Désolé, mais nous n'avons pas trouvé d'articles correspondant à votre recherche.
        <br className="mb-2" />
        Veuillez vérifier l'orthographe ou essayer des termes de recherche différents.
      </>
    ) : (
      <>
        Il semblerait que vous n'ayez pas encore créé d'article. Cliquez sur le bouton ci-dessus
        pour commencer.
      </>
    );

  return articles?.items?.length === 0 ? (
    <EmptyListMessage text={emptyListMessage} />
  ) : (
    <>
      {favoriteArticles?.length > 0 && <h2 className="mt-2 mb-1">Articles</h2>}
      <ArticlesContainer>
        {articles?.items?.length > 0 &&
          articles?.items?.map((el, key) => (
            <ArticleCardItem
              key={key}
              title={el.titre}
              image={el.image}
              content={el.contenu}
              author={el.auteur}
              id={el.id}
              page={page}
              isFavorite={el.isFavorite}
              slug={el.slug}
              date={el.createdAt}
            />
          ))}
      </ArticlesContainer>
      <Pagination
        current={articles?.current_page_number}
        total={articles?.num_totals_items}
        onChange={page => dispatch(filterBlog({ page, query }))}
      />
    </>
  );
};

export default ArticlesList;
