export const demandColumns = [
  {
    title: 'Nom et Prénom',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'Numéro de téléphone',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
  },
  {
    title: 'Email',
    dataIndex: 'emailAddress',
    key: 'emailAddress',
  },
  {
    title: "Date d'envoi",
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message',
  },
  {
    title: "Nom de l'école",
    dataIndex: 'schoolFirstName',
    key: 'schoolFirstName',
  },
  // Temporary comment
  // {
  //   title: 'Actions',
  //   dataIndex: 'actions',
  //   key: 'actions',
  // },
];
