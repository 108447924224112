import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { notification, Space } from 'antd';
import { Spin } from 'antd';

import {
  resetCreateBlogSuccess,
  resetDeleteBlogSuccess,
  resetEditBlogSuccess,
} from 'redux/slices/blog/articles/blogSlice';
import FavoriteArticles from './favorites';
import ArticlesList from './articles/ArticlesList';

const ArticleList = ({ page }) => {
  const dispatch = useDispatch();

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (type, action) => {
    let description = '';
    if (action === 'delete') {
      description = 'Le blog a été supprimé avec succès';
    } else if (action === 'add') {
      description = 'Le blog a été ajouté avec succès';
    } else if (action === 'edit') {
      description = 'Le blog a été modifié avec succès';
    }
    api[type]({
      description,
    });
  };

  const { isLoading } = useSelector(state => state.blog);
  const deleteBlogIsSuccess = useSelector(state => state.blog.deleteBlog.isSuccess);
  const createBlogIsSuccess = useSelector(state => state.blog.createBlog.isSuccess);
  const editBlogIsSuccess = useSelector(state => state.blog.updateBlog.isSuccess);

  useEffect(() => {
    if (deleteBlogIsSuccess) {
      openNotification('success', 'delete');
      dispatch(resetDeleteBlogSuccess());
    }
  }, [deleteBlogIsSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (createBlogIsSuccess) {
      openNotification('success', 'add');
      dispatch(resetCreateBlogSuccess());
    }
  }, [createBlogIsSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (editBlogIsSuccess) {
      openNotification('success', 'edit');
      dispatch(resetEditBlogSuccess());
    }
  }, [editBlogIsSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {contextHolder}
      {isLoading && (
        <Space direction="vertical" className="spinner">
          <Space>
            <Spin tip="Loading" size="large" className="spin">
              <div className="content" />
            </Spin>
          </Space>
        </Space>
      )}

      <FavoriteArticles />
      <ArticlesList page={page} />
    </>
  );
};

export default ArticleList;
