import { createAsyncThunk } from '@reduxjs/toolkit';
import { API, API_HOST } from 'config/api';
import axiosService from 'services/axiosService';

export const filterBlog = createAsyncThunk(
  'filterBlog',
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const { query, page } = data;
    const URL = `${API_HOST + API.blog.filter}?page=${page}`;

    return axiosService
      .post(URL, { query })
      .then(response => fulfillWithValue(response.data))
      .catch(error => rejectWithValue(error.message));
  }
);

export const getFavoriteArticles = createAsyncThunk(
  'getFavoriteArticles',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    const URL = API_HOST + API.blog.favorite;

    return axiosService
      .get(URL)
      .then(res => fulfillWithValue(res.data))
      .catch(err => rejectWithValue(err.message));
  }
);

export const createBlog = createAsyncThunk(
  'createBlog',
  async (data, { dispatch, rejectWithValue }) => {
    const URL = API_HOST + API.blog.create;

    return axiosService
      .post(URL, data)
      .then(() => dispatch(filterBlog({ query: null, page: 1 })))
      .catch(rejectWithValue(error => error.message));
  }
);

export const deleteBLog = createAsyncThunk(
  'deleteBLog',
  async (id, { dispatch, rejectWithValue }) => {
    const URL = `${API_HOST + API.blog.delete}/${id}`;

    return await axiosService
      .delete(URL)
      .then(() => {
        dispatch(filterBlog({ query: null, page: 1 }));
        dispatch(getFavoriteArticles());
      })
      .catch(error => rejectWithValue(error.message));
  }
);

export const editBlog = createAsyncThunk(
  'editBlog',
  async (data, { fulfillWithValue, rejectWithValue, dispatch }) => {
    const URL = `${API_HOST + API.blog.edit}${data.id}/edit `;

    return axiosService
      .patch(URL, data.values)
      .then(res => fulfillWithValue(res.data))
      .finally(() => {
        dispatch(filterBlog({ query: null, page: 1 }));
        dispatch(getFavoriteArticles());
      })
      .catch(error => rejectWithValue(error.message));
  }
);

export const getOneBlog = createAsyncThunk(
  'getOneBlog',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const URL = API_HOST + API.blog.getOneBlog(id);
      const response = await axiosService.get(URL, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const editImageBlog = createAsyncThunk(
  'editImageBLog',
  async (formData, { rejectWithValue }) => {
    try {
      const URL = API_HOST + API.blog.editImageBLog;
      const response = await axiosService.post(URL, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getSpotlightBlog = createAsyncThunk(
  'getSpotlightBlog',
  async (data, { rejectWithValue }) => {
    const URL = API_HOST + API.spotlightBlog.list;
    try {
      const result = await axiosService.get(`${URL}`, data);
      return result.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const editSpotlightBlog = createAsyncThunk(
  'editSpotlightBlog',
  async (data, { rejectWithValue }) => {
    try {
      const URL = API_HOST + API.spotlightBlog.edit(data.id);
      const response = await axiosService.patch(URL, data.values);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const filterBlogByDate = createAsyncThunk(
  'filterBlogByDate',
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const { date, page } = data;
    const URL = `${API_HOST + API.blog.filterDate}?page=${page}`;

    return axiosService
      .post(URL, date)
      .then(response => fulfillWithValue(response.data))
      .catch(error => rejectWithValue(error.message));
  }
);
