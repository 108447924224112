import { createSlice } from '@reduxjs/toolkit';
import { createPermit, getPermit } from './permitAsyncThunk';

const initialState = {
  isLoading: false,
  isSuccess: false,
  errorMessage: '',

  permitsList: {
    data: null,
    isLoading: false,
    error: false,
  },
};

export const permitSlice = createSlice({
  name: 'Permit',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      //createPermit
      .addCase(createPermit.pending, state => {
        state.isLoading = true;
      })
      .addCase(createPermit.fulfilled, state => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(createPermit.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = action.payload;
      })

      //getPermit
      .addCase(getPermit.pending, state => {
        state.permitsList.isLoading = true;
      })
      .addCase(getPermit.fulfilled, (state, action) => {
        state.permitsList.isLoading = false;
        state.permitsList.data = action.payload;
      })
      .addCase(getPermit.rejected, (state, action) => {
        state.permitsList.isLoading = false;
        state.permitsList.error = action.payload;
      });
  },
});

export default permitSlice.reducer;
