import { createSlice } from '@reduxjs/toolkit';

import staticData from 'config/config';
import { editSettings } from '../settings/settingsAsyncThunk';
import { convertUrlHttps } from 'utils/conversion';

const initialState = {
  data: staticData.darkMode,
  rtlData: staticData.rtl,
  topMenu: staticData.topMenu,
  logo: null,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    changeLayoutMode: (state, action) => {
      state.data = action.payload;
    },
    changeRtlMode: (state, action) => {
      state.rtlData = action.payload;
    },
    changeMenuMode: (state, action) => {
      state.topMenu = action.payload;
    },
    setLogo: (state, action) => {
      state.logo = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      // get admin settings
      .addCase(editSettings.fulfilled, (state, action) => {
        state.logo = convertUrlHttps(action.payload.data.lienLogo);
      });
  },
});

export const { changeLayoutMode, changeRtlMode, changeMenuMode, setLogo } = layoutSlice.actions;

export default layoutSlice.reducer;
