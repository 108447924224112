import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal } from 'antd';

import { resetAdminListModal } from 'redux/slices/adminList/adminListSlice';
import SubmitButton from 'components/UI/Buttons/SubmitButton';
import { deleteAdmin } from 'redux/slices/adminList/adminListAsyncThunk';

const DeleteAdminModal = () => {
  const dispatch = useDispatch();

  const { modal } = useSelector(state => state.adminList);

  const handleCancel = () => dispatch(resetAdminListModal());

  const handleSubmit = () => dispatch(deleteAdmin(modal.id));
  
  const footer = (
    <div>
      <Button onClick={handleCancel}>Annuler</Button>
      <SubmitButton
        text="Supprimer"
        type="warning"
        spinClassName="loader-spin ml-50"
        isLoading={modal.isLoading}
        onClick={handleSubmit}
      />
    </div>
  );

  return (
    <Modal
      title="Confirmation de suppression d'administrateur"
      open={modal.modalRef === 'delete-admin'}
      onCancel={handleCancel}
      footer={footer}
      closable={false}
      centered
    >
      <p>
        Êtes-vous sûr de vouloir supprimer cet administrateur ?
        <br />
        Cette action est irréversible et peut avoir un impact sur les autorisations et les
        fonctionnalités de l'application pour cet utilisateur.
      </p>
    </Modal>
  );
};

export default DeleteAdminModal;
