import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Form, Input, Modal } from 'antd';

import { resetAdminListModal } from 'redux/slices/adminList/adminListSlice';
import { addAdmin, editPassword } from 'redux/slices/adminList/adminListAsyncThunk';
import SubmitButton from 'components/UI/Buttons/SubmitButton';
import { useForm } from 'antd/lib/form/Form';

const AddEditAdminModal = () => {
  const dispatch = useDispatch();
  const [modalForm] = useForm();

  const modal = useSelector(state => state.adminList.modal);

  const title = modal?.editMode ? 'Modifier le mot de passe' : 'Ajouter un administrateur';
  const submitButtonText = modal?.editMode ? 'Modifier' : 'Ajouter';

  const handleCancel = () => {
    if (!modal.isLoading) {
      dispatch(resetAdminListModal());
      modalForm.resetFields();
    }
  };

  const handleSubmit = form => {
    if (modal?.editMode) {
      dispatch(editPassword({ form, id: modal.id })).then(() => modalForm.resetFields());
    } else {
      dispatch(addAdmin(form)).then(() => modalForm.resetFields());
    }
  };

  return (
    <Modal
      title={title}
      open={modal.modalRef === 'add-edit-admin'}
      onCancel={handleCancel}
      closable={false}
      footer={null}
      centered
    >
      <Form onFinish={handleSubmit} disabled={modal.isLoading} form={modalForm}>
        {!modal.editMode && (
          <Form.Item
            label="email"
            rules={[{ required: true, message: 'Veuillez fournir une adresse email valide' }]}
            name="email"
          >
            <Input type="email" />
          </Form.Item>
        )}

        <Form.Item
          label={modal.editMode ? 'Nouveau mot de passe' : 'Mot de passe'}
          className="mt-1"
          rules={[
            {
              required: true,
              message: 'Veuillez entrer un mot de passe',
            },
          ]}
          name={modal.editMode ? 'newPassword' : 'password'}
        >
          <Input />
        </Form.Item>

        <div className="d-flex justify-content-end mt-1">
          <Button onClick={handleCancel} className="mr-1">
            Annuler
          </Button>

          <SubmitButton
            type="primary"
            htmlType="submit"
            text={submitButtonText}
            isLoading={modal?.isLoading}
            spinClassName="loader-spin ml-50"
          />
        </div>
      </Form>
    </Modal>
  );
};

export default AddEditAdminModal;
