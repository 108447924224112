export const columns = [
  {
    title: 'Nom commercial',
    dataIndex: 'nomCommercial',
    key: 'nomCommercial',
  },
  {
    title: 'Nom du responsable',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Numéro de téléphone',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
  },
  {
    title: 'Statut',
    dataIndex: 'status',
    key: 'status',
  },
];

export const requestColumns = [
  {
    title: 'Nom commercial',
    dataIndex: 'nomCommercial',
    key: 'nomCommercial',
  },
  {
    title: 'Nom du responsable',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Numéro de téléphone',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Statut',
    dataIndex: 'status',
    key: 'status',
  },
];
