import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_HOST, API } from 'config/api';
import jwtDecode from 'jwt-decode';
import { setRefreshToken, setToken } from 'services/authenticationService';

const login = async userData => {
  const BASE_URL = API_HOST + API.auth.login;

  const response = await axios.post(`${BASE_URL}`, userData);

  if (response.data) {
    setToken(response.data.token);
    setRefreshToken(response.data.refresh_token);
  }
  return response.data;
};

export const loginThunk = createAsyncThunk('login', async (data, thunkAPI) => {
  try {
    const response = await login(data.data);

    if (response && response.token) {
      const decodedToken = jwtDecode(response.token);

      if (decodedToken.roles.includes('ROLE_ADMIN')) {
        data.history.push('/admin/auto-ecoles');
        return response;
      }
      return thunkAPI.rejectWithValue('Access denied. Only admin can log in.');
    }
  } catch (error) {
    const message =
      (error.message && error.message.data && error.message.data.message) ||
      error.message ||
      error.string();
    return thunkAPI.rejectWithValue(message);
  }
});
