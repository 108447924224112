import Styled from 'styled-components';

const SearchInputContainer = Styled.div`
position: relative; 

    input {
        border-radius: 23px;
        border: none;
        padding: 10px;
        border: 1px solid #e3e6ef;
    }

    input::placeholder {
        font-size: 13px;
        max-width: 90%; 
        overflow: hidden;
        text-overflow: ellipsis; 
        white-space: nowrap;
        }
    }

    i {
        position: absolute;
        top: 0;
        color: #55759C;
        bottom: 0;
        margin: auto;
        right: 14px;
        height: fit-content;
    }
`;

export { SearchInputContainer };
