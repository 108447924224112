import { createSlice } from '@reduxjs/toolkit';
import { addAdmin, deleteAdmin, editPassword, getAdminsList } from './adminListAsyncThunk';

const initialState = {
  data: [],
  isLoading: false,
  error: null,
  modal: { modalRef: null, isLoading: false },
};

export const adminListSlice = createSlice({
  name: 'Admin',
  initialState,
  reducers: {
    setAdminListModal: (state, action) => {
      state.modal = { ...state.modal, ...action.payload };
    },
    resetAdminListModal: state => {
      state.modal = { modalRef: null, isLoading: false };
    },
  },
  extraReducers: builder => {
    builder
      //get admins list
      .addCase(getAdminsList.pending, state => {
        state.isLoading = true;
      })
      .addCase(getAdminsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getAdminsList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      //add new admin
      .addCase(addAdmin.pending, state => {
        state.modal.isLoading = true;
      })
      .addCase(addAdmin.fulfilled, (state, action) => {
        state.modal = { modalRef: null, isLoading: false };
        state.data = [...state.data, action.payload];
      })
      .addCase(addAdmin.rejected, state => {
        state.modal = { modalRef: 'error', isLoading: false };
      })

      //edit admin password
      .addCase(editPassword.pending, state => {
        state.modal.isLoading = true;
      })
      .addCase(editPassword.fulfilled, state => {
        state.modal = { modalRef: null, isLoading: false };
      })
      .addCase(editPassword.rejected, state => {
        state.modal = { modalRef: 'error', isLoading: false };
      })

      //delete admin
      .addCase(deleteAdmin.pending, state => {
        state.modal.isLoading = true;
      })
      .addCase(deleteAdmin.fulfilled, (state, action) => {
        state.modal = { modalRef: null, isLoading: false };
        const newData = state.data.filter(admin => admin.id !== action.payload);
        state.data = newData;
      })
      .addCase(deleteAdmin.rejected, state => {
        state.modal = { modalRef: 'error', isLoading: false };
      });
  },
});

export const { setAdminListModal, resetAdminListModal } = adminListSlice.actions;

export default adminListSlice.reducer;
