import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal } from 'antd';
import SubmitButton from 'components/UI/Buttons/SubmitButton';

import { resetFeaturedArticlesModal } from 'redux/slices/blog/featured-articles/featuredArticlesSlice';
import { deleteFeaturedArticle } from 'redux/slices/blog/featured-articles/featuredArticlesThunks';

const DeleteSpotlightBlogModal = () => {
  const dispatch = useDispatch();

  const { modal } = useSelector(state => state.featuredArticles);

  const onDeleteArticleHandler = () => dispatch(deleteFeaturedArticle(modal.id));
  const handleCancel = () => !modal?.isLoading && dispatch(resetFeaturedArticlesModal());

  const footer = (
    <div className="d-flex justify-content-end mt-1">
      <Button onClick={handleCancel} className="mr-1">
        Annuler
      </Button>

      <SubmitButton
        type="warning"
        text="Supprimer"
        isLoading={modal?.isLoading}
        spinClassName="loader-spin ml-50"
        onClick={onDeleteArticleHandler}
      />
    </div>
  );

  return (
    <Modal
      open={modal.modalRef === 'delete-article'}
      onCancel={handleCancel}
      closable={false}
      footer={footer}
    >
      <p>
        Vous êtes sur le point de supprimer l'article à la une.
        <br />
        Cette action est irréversible et l'article sera définitivement supprimé.
        <br />
        Êtes-vous sûr de vouloir continuer ?
      </p>
    </Modal>
  );
};

export default DeleteSpotlightBlogModal;
