import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'antd/lib/form/Form';

import { Button, Col, Form, Image, Input, Modal, Row, Switch, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import SubmitButton from 'components/UI/Buttons/SubmitButton';

import { regex } from 'config/constants/regex';
import { convertUrlHttps } from 'utils/conversion';
import { resetFeaturedArticlesModal } from 'redux/slices/blog/featured-articles/featuredArticlesSlice';
import {
  createFeaturedArticle,
  editFeaturedArticle,
  getFeaturedArticle,
  getFeaturedArticles,
} from 'redux/slices/blog/featured-articles/featuredArticlesThunks';

const SpotlightBlogModal = () => {
  const dispatch = useDispatch();
  const [form] = useForm();

  const {
    modal,
    featuredArticle: { data: featuredArticle },
    filter,
  } = useSelector(state => state.featuredArticles);

  const [articleFileList, setArticleFileList] = useState([]);
  const [authorFileList, setAuthorFileList] = useState([]);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(true)
  const [removeAuthorImage, setRemoveAuthorImage] = useState(false)
  const [articleImageUrl, setArticleImageUrl] = useState('')
  const [authorImageUrl, setAuthorImageUrl] = useState('')

  const data = modal?.data;
  const isOpen =
    modal.modalRef === 'add-featured-article' || modal.modalRef === 'edit-featured-article';

  const onCloseModalHandler = () => {
    if (!modal?.isLoading) {
      dispatch(resetFeaturedArticlesModal());
    }
  };

  const formChangeHandler = () => {
    setDisabledSubmitButton(false)
  }

  const handleSubmit = values => {
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('author', values.author);
    formData.append('urlArticle', values.urlArticle);
    formData.append('description', values.description);
    formData.append('isPublished', values.isPublished);
    formData.append('imageArticle', articleFileList?.file?.originFileObj);
    formData.append('imageAuthor', authorFileList?.file?.originFileObj ?? null);

    const hasFeaturedArticle = !!featuredArticle;

    if (hasFeaturedArticle && values.isPublished) {
      setIsWarningModalOpen(true);
    } else {
      dispatch(createFeaturedArticle(formData)).then(() => {
        dispatch(getFeaturedArticles({ filter, page: 1 }));
        dispatch(getFeaturedArticle());
      });
    }
  };

  const deleteAuthorImage = () => {
    setAuthorImageUrl('')
    setRemoveAuthorImage(true)
    setDisabledSubmitButton(false)
  }

  const handleSubmitEdit = values => {
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('author', values.author);
    formData.append('urlArticle', values.urlArticle);
    formData.append('description', values.description);
    formData.append('isPublished', values.isPublished);
    if (articleFileList?.file?.originFileObj) {
      formData.append('imageArticle', articleFileList?.file?.originFileObj)
    }
    if (authorFileList?.file?.originFileObj) {
      formData.append('imageAuthor', authorFileList?.file?.originFileObj)
    }
    if (removeAuthorImage) {
      formData.append('removeAuthorImage', removeAuthorImage);
    }

    const hasFeaturedArticle = !!featuredArticle;

    if (hasFeaturedArticle && values.isPublished && !data.isPublished) {
      setIsWarningModalOpen(true);
    } else {
      dispatch(editFeaturedArticle({ data: formData, id: data.id })).then(() => {
        dispatch(getFeaturedArticles({ filter, page: 1 }));
        dispatch(getFeaturedArticle());
      });
    }
  };

  const articleImageUploadHandler = data => {
    if (data.fileList.length === 1) {
      setArticleFileList(data);
    } else {
      setArticleFileList([]);
    }
  };

  const authorImageUploadHandler = data => {
    if (data.fileList.length === 1) {
      setAuthorFileList(data);
      setRemoveAuthorImage(false)
    } else {
      setRemoveAuthorImage(true)
      setAuthorFileList([]);
    }
  };

  const onCloseWarningModal = () => !modal.isLoading && setIsWarningModalOpen();

  const onConfirmPublish = () => {
    const values = form.getFieldsValue();

    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('author', values.author);
    formData.append('urlArticle', values.urlArticle);
    formData.append('description', values.description);
    formData.append('isPublished', values.isPublished);
    if (articleFileList?.file?.originFileObj) {
      formData.append('imageArticle', articleFileList?.file?.originFileObj)
    }
    if (authorFileList?.file?.originFileObj) {
      formData.append('imageAuthor', authorFileList?.file?.originFileObj)
    }
    if (removeAuthorImage) {
      formData.append('removeAuthorImage', removeAuthorImage);
    }

    if (data) {
      dispatch(editFeaturedArticle({ data: formData, id: data.id })).then(() => {
        setIsWarningModalOpen(false);
        dispatch(getFeaturedArticles({ filter, page: 1 }));
        dispatch(getFeaturedArticle());
      });
    } else {
      dispatch(createFeaturedArticle(formData)).then(() => {
        setIsWarningModalOpen(false);
        dispatch(getFeaturedArticles({ filter, page: 1 }));
        dispatch(getFeaturedArticle());
      });
    }
  };

  useEffect(() => {
    if (data) {
      setArticleImageUrl(convertUrlHttps(data?.imageArticle))
      setAuthorImageUrl(data?.imageAuthor ? convertUrlHttps(data?.imageAuthor) : '')
    }
  }, [data])

  useEffect(() => {
    if (!isOpen) {
      form.resetFields()
      setArticleFileList([])
      setAuthorFileList([])
      setArticleImageUrl('')
      setAuthorImageUrl('')
      setDisabledSubmitButton(true)
    }
  }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Modal
        open={isOpen}
        width="80vw"
        onCancel={onCloseModalHandler}
        key={data?.id}
        footer={null}
      >
        <Form
          encType="multipart/form-data"
          onFinish={data ? handleSubmitEdit : handleSubmit}
          onChange={formChangeHandler}
          disabled={modal?.isLoading}
          form={form}
        >
          <Row>
            <Col md={12}>
              <Form.Item
                name="imageArticle"
                label="Image de l'article"
                rules={[{ required: false, message: "Vous devez insérer l'image de l'article" }]}
              >
                <Upload
                  listType="picture-card"
                  maxCount={1}
                  showUploadList={{ showPreviewIcon: false }}
                  onChange={articleImageUploadHandler}
                  fileList={articleFileList.fileList}
                >
                  {
                    articleFileList.length === 0 ? articleImageUrl !== '' ?
                      <Image src={articleImageUrl} preview={false} /> :
                      <div>
                        <PlusOutlined />
                      </div> :
                      null
                  }
                </Upload>
              </Form.Item>
            </Col>

            <Col md={12}>
              <Form.Item name="imageAuthor" label="Image de l'auteur">
                <Upload
                  showUploadList={{ showPreviewIcon: false }}
                  listType="picture-card"
                  maxCount={1}
                  fileList={authorFileList.fileList}
                  onChange={authorImageUploadHandler}
                >
                  {
                    authorFileList.length === 0 ? authorImageUrl !== '' ?
                      <Image src={authorImageUrl} preview={false} /> :
                      <div>
                        <PlusOutlined />
                      </div> :
                      null
                  }
                </Upload>
              </Form.Item>
              {data?.imageAuthor && authorImageUrl !== '' && <Button className="delete-author-image" type='danger' onClick={deleteAuthorImage}>supprimer</Button>}
            </Col>
          </Row>

          <div className="d-flex full-width mb-1 mt-1">
            <Form.Item
              label="Titre"
              rules={[{ required: true, message: 'Le titre est obligatoire' }]}
              className="full-width mr-1"
              name="title"
              initialValue={data?.title}
            >
              <Input placeholder="Titre de l'article" />
            </Form.Item>

            <Form.Item
              label="Auteur"
              rules={[
                { required: true, message: "Vous devez insérer le nom de l'auteur!" },
                {
                  pattern: regex.simpleString,
                  message:
                    "Le nom de l'auteur ne doit ni contenir un caractère spécial ni d'espace au début et à la fin du nom.",
                },
                {
                  min: 3,
                  message: "Le nom de l'auteur doit être composé d'au moins 3 caractères.",
                },
              ]}
              className="full-width mr-1"
              name="author"
              initialValue={data?.author}
            >
              <Input placeholder="Nom de l'auteur" />
            </Form.Item>
          </div>
          <Row>
            <Col md={24}>
              <Form.Item
                label="Lien externe"
                className="full-width"
                name="urlArticle"
                rules={[{ required: true, message: "Vous devez insérer le lien de l'article" }]}
                initialValue={data?.urlArticle}
              >
                <Input placeholder="Lien d'un article externe" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={24} className="mt-1">
              <Form.Item
                label="Description"
                className="full-width"
                name="description"
                rules={[
                  { required: true, message: "Vous devez insérer la description de l'article" },
                ]}
                initialValue={data?.description}
              >
                <Input.TextArea placeholder="Description de l'article" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label="Choisir comme article à la une"
            className="full-width mt-1 featured-article-modal-switcher"
            name="isPublished"
            valuePropName="checked"
            initialValue={data?.isPublished ?? true}
          >
            <Switch disabled={data?.isPublished} onChange={formChangeHandler} />
          </Form.Item>
          {data?.isPublished && <span>(au moins un article doit être à la une)</span>}

          <div className="mt-1 d-flex justify-content-end">
            <Button onClick={onCloseModalHandler}>Annuler</Button>
            <SubmitButton
              text={data ? "Modifier" : "Ajouter"}
              type="primary"
              htmlType="submit"
              spinClassName="loader-spin ml-50"
              isLoading={modal.isLoading}
              disabled={disabledSubmitButton}
            />
          </div>
        </Form>
      </Modal>

      <Modal
        title="Attention"
        open={isWarningModalOpen}
        footer={
          <SubmitButton
            text="Oui"
            type="primary"
            spinClassName="loader-spin ml-50"
            isLoading={modal.isLoading}
            onClick={onConfirmPublish}
            disabled={modal.isLoading}
          />
        }
        onCancel={onCloseWarningModal}
      >
        <p>
          Vous êtes sur le point de créer un nouvel article à la une. Veuillez noter que cela
          remplacera l'article à la une existant. Êtes-vous sûr de vouloir continuer ?
        </p>
      </Modal>
    </>
  );
};

export default SpotlightBlogModal;
