import { createSlice } from '@reduxjs/toolkit';
import {
  editSettings,
  addTag,
  deleteTag,
  getTags,
  getUnidriveLogo,
  adminSettings,
} from './settingsAsyncThunk';

const initialState = {
  data: {},
  isLoading: false,
  error: null,

  daysBeforeDeletion: {},

  tags: [],
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // edit daysBeforeDeletion
      .addCase(editSettings.fulfilled, (state, action) => {
        state.data = { ...state.data, ...action.payload };
      })
      .addCase(editSettings.rejected, (state, action) => {
        state.error = action.error.message;
      })

      // get daysBeforeDeletion
      .addCase(adminSettings.pending, state => {
        state.isLoading = true;
      })
      .addCase(adminSettings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = { ...state.data, ...action.payload };
      })
      .addCase(adminSettings.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // get Unidrive Logo
      .addCase(getUnidriveLogo.pending, state => {
        state.isLoading = true;
      })
      .addCase(getUnidriveLogo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = { ...state.data, logo: action.payload };
      })
      .addCase(getUnidriveLogo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // get tags
      .addCase(getTags.pending, state => {
        state.isLoading = true;
      })
      .addCase(getTags.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tags = action.payload;
      })
      .addCase(getTags.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // add tag
      .addCase(addTag.fulfilled, (state, action) => {
        state.tags = [...state.tags, action.payload.data];
      })
      .addCase(addTag.rejected, (state, action) => {
        state.error = action.error.message;
      })

      // delete tag
      .addCase(deleteTag.fulfilled, (state, action) => {
        const newTagsArray = state.tags.filter(tag => tag.id !== action.payload);
        state.tags = newTagsArray;
      })
      .addCase(deleteTag.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});

export const { addDaysBeforeDeletionSettings, adminSettingsSettings } = settingsSlice.actions;
export default settingsSlice.reducer;
