import React, { useState } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';

import { Menu } from 'antd';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';

const MenuItems = ({ toggleCollapsed }) => {
  const { path } = useRouteMatch();

  var pathName = window.location.pathname.split('/');
  const slug = window.location.pathname.replace('/admin/', '');

  const [openKeys, setOpenKeys] = useState(
    pathName.length > 2 ? pathName.slice(2, pathName.length) : []
  );

  const onOpenChange = keys => setOpenKeys([keys.pop()]);

  const items = [
    {
      key: 'driving-schools',
      icon: (
        <>
          <FeatherIcon icon="list" />
          <span className="ml-1">Auto-Ecoles</span>
        </>
      ),
      children: [
        {
          key: 'all-driving-schools',
          icon: (
            <NavLink className="menuItem-icon" onClick={toggleCollapsed} to={`${path}/auto-ecoles`}>
              <FeatherIcon icon="users" size={14} />
              <span>Liste des auto-Ecoles</span>
            </NavLink>
          ),
        },
        {
          key: 'driving-schools-request',
          icon: (
            <NavLink
              className="menuItem-icon"
              onClick={toggleCollapsed}
              to={`${path}/demandes-auto-ecoles`}
            >
              <FeatherIcon icon="archive" size={15} />
              <span>Demandes de vérification</span>
            </NavLink>
          ),
        },
      ],
    },
    {
      key: 'blog',
      icon: (
        <>
          <FeatherIcon icon="trello" />
          <span className="ml-1">Blog</span>
        </>
      ),
      children: [
        {
          key: 'articles',
          icon: (
            <NavLink className="menuItem-icon" onClick={toggleCollapsed} to={`${path}/articles`}>
              <FeatherIcon icon="file-text" size={15} />
              <span>Articles</span>
            </NavLink>
          ),
        },
        {
          key: 'featured-articles',
          icon: (
            <NavLink
              className="menuItem-icon"
              onClick={toggleCollapsed}
              to={`${path}/article-a-la-une`}
            >
              <FeatherIcon icon="star" size={15} />
              <span>Article à la une</span>
            </NavLink>
          ),
        },
      ],
    },
    {
      key: 'liste-demandes',
      icon: (
        <NavLink className="menuItem-icon" onClick={toggleCollapsed} to={`${path}/liste-demandes`}>
          <FeatherIcon icon="message-square" />
          <span>Liste des demandes</span>
        </NavLink>
      ),
    },
    {
      key: 'liste-admins',
      icon: (
        <NavLink className="menuItem-icon" onClick={toggleCollapsed} to={`${path}/liste-admins`}>
          <FeatherIcon icon="users" />
          <span>Liste des admins</span>
        </NavLink>
      ),
    },
    {
      key: 'parametres',
      icon: (
        <NavLink className="menuItem-icon" onClick={toggleCollapsed} to={`${path}/parametres`}>
          <FeatherIcon icon="settings" />
          <span>Paramètres</span>
        </NavLink>
      ),
    },
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      mode="inline"
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
      defaultSelectedKeys={[slug]}
      items={items}
    />
  );
};

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
