import React from 'react';
import DrivingSchoolPage from './components';
import { Container } from 'container/styled';

const DrivingSchoolsRequest = () => {
  return (
    <Container>
      <DrivingSchoolPage />
    </Container>
  );
};

export default DrivingSchoolsRequest;
