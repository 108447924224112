import React from 'react';
import { useSelector } from 'react-redux';

import ArticleCardItem from './ArticleCardItem';
import { ArticlesContainer } from '../style';

const SpotlightBlogArticle = () => {
  const featuredArticle = useSelector(state => state.featuredArticles.featuredArticle.data);

  return (
    featuredArticle && (
      <>
        <h2 className="mt-2 mb-1">Article à la une</h2>
        <ArticlesContainer>
          <ArticleCardItem
            key={featuredArticle.id}
            title={featuredArticle.title}
            image={featuredArticle.imageArticle}
            content={featuredArticle.description}
            author={featuredArticle.author}
            id={featuredArticle.id}
            date={featuredArticle.createdAt}
            data={featuredArticle}
          />
        </ArticlesContainer>
      </>
    )
  );
};

export default SpotlightBlogArticle;
