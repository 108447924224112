import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RichTextEditor from 'react-rte';

import { Form, Input, Modal, Upload } from 'antd';

import { createBlog } from 'redux/slices/blog/articles/blogAsyncThunk';
import { PlusOutlined } from '@ant-design/icons';
import { beforeUpload } from 'utils/uploads';

const AddArticleModal = ({ open, closeModalHandler }) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(state => state.blog.createBlog.isLoading);
  const [addBlogForm] = Form.useForm();
  const [imageFile, setImageFile] = useState(null);
  const [validForm, setValidForm] = useState(false);

  const [state, setState] = useState({
    value: RichTextEditor.createEmptyValue('', 'html'),
    tags: [],
  });

  const onChanges = value => {
    setState({ value });
  };
  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    const fileList = e && e.fileList;
    const filteredFileList = fileList.filter(file => {
      const isImage = file.type.startsWith('image/');
      return isImage;
    });

    return filteredFileList;
  };

  const handleImageChange = info => {
    const { fileList } = info;

    if (fileList.length > 0) {
      const selectedFile = fileList[fileList.length - 1];
      if (selectedFile.originFileObj) {
        setImageFile(selectedFile.originFileObj);
      } else {
        setImageFile(null);
      }
    } else {
      setImageFile(null);
    }
  };

  const createBlogHandler = () => {
    const formData = new FormData();

    if (imageFile) {
      formData.append('imageBlog', imageFile);
    }
    formData.append('titre', addBlogForm.getFieldValue('title'));
    formData.append('auteur', addBlogForm.getFieldValue('author'));
    formData.append('contenuBlog', state.value.toString('html'));
    formData.append('isFavorite', 0);

    dispatch(createBlog(formData)).then(() => {
      closeModalHandler();
      addBlogForm.resetFields();
      setImageFile(null);
      setState({ value: RichTextEditor.createEmptyValue('', 'html'), tags: [] });
    });
  };

  const cancelModal = () => {
    addBlogForm.resetFields();
    setImageFile(null);
    setState({ value: RichTextEditor.createEmptyValue('', 'html'), tags: [] });
    closeModalHandler();
  };

  const isFormEmpty = () => {
    const { title, author } = addBlogForm.getFieldsValue();
    setValidForm(!!(title && author && state.value));
  };

  return (
    <Modal
      title="Publier un nouvel article"
      open={open}
      onOk={createBlogHandler}
      okButtonProps={{ disabled: !validForm || isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
      onCancel={cancelModal}
      okText="Publier maintenant"
      width="80vw"
    >
      <Form form={addBlogForm} encType="multipart/form-data" onValuesChange={isFormEmpty}>
        <Form.Item name="image" valuePropName="fileList" getValueFromEvent={normFile}>
          <Upload
            listType="picture-card"
            beforeUpload={beforeUpload}
            maxCount={1}
            onChange={handleImageChange}
            showUploadList={{
              showPreviewIcon: false,
            }}
          >
            {imageFile ? null : (
              <div>
                <PlusOutlined />
              </div>
            )}
          </Upload>
        </Form.Item>
        <div className="d-flex full-width mb-1 mt-1">
          <Form.Item
            label="Titre"
            rules={[{ required: true, message: 'Le titre est obligatoire' }]}
            className="full-width mr-1"
            name="title"
          >
            <Input placeholder="Titre de l'article" />
          </Form.Item>

          <Form.Item
            label="Auteur"
            rules={[{ required: true, message: "Vous devez insérer le nom de l'auteur!" }]}
            className="full-width"
            name="author"
          >
            <Input placeholder="Nom de l'auteur" />
          </Form.Item>
        </div>
        {state?.value && (
          <RichTextEditor
            placeholder="Contenu de l'article..."
            value={state.value}
            onChange={onChanges}
          />
        )}
      </Form>
    </Modal>
  );
};

export default AddArticleModal;
