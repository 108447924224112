import Styled from 'styled-components';

const TablePageHeaderContainer = Styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    @media screen and (max-width: 1024px){
    flex-direction: column;
    }

    .table-result {
        margin-bottom: 0;
        font-weight: 400;
        font-size: 14px;
        color: #55759C;
        padding: 2px 20px;
        margin-left: 20px;
        border-left: 1px solid #C2CDDB;   
    }

    .filter_container{
        @media screen and (max-width: 1024px){
            padding-right: 4rem;
        }

        @media screen and (max-width: 599px){
            flex-direction: column;
            padding-right: 0;
        }

        .filter_text{
            padding-right:10px;
            margin-left: 0;

            @media screen and (max-width: 1024px){
                flex-direction: column;
                border-left: none
            }
        }
    }

    .buttons-container{
        display: flex;
        align-items:center;
        gap: 1.5rem;

        @media screen and (max-width: 1024px){
            flex-direction: column;
        }

        .filters{
            display:flex;
            align-items: center;
            gap: 1.5rem;

            @media screen and (max-width: 599px){
                flex-direction: column;
            }
        }
    }

    i{
        color: #55759C !important;
        font-size: 14px;
    }
`;

export { TablePageHeaderContainer };
