import React from 'react';
import { useSelector } from 'react-redux';
import ArticleCardItem from '../card/ArticleCardItem';
import { ArticlesContainer } from 'container/BlogContainer/blog/style';

const FavoriteArticles = () => {
  const favoriteArticles = useSelector(state => state.blog.favoriteArticles.data);

  return (
    favoriteArticles?.length > 0 && (
      <div>
        <h2 className="mt-2 mb-1">Articles favoris</h2>

        <ArticlesContainer>
          {favoriteArticles.map(article => (
            <ArticleCardItem
              key={article.id}
              title={article.titre}
              image={article.image}
              content={article.contenu}
              author={article.auteur}
              id={article.id}
              isFavorite={article.isFavorite}
              slug={article.slug}
              date={article.createdAt}
            />
          ))}
        </ArticlesContainer>
      </div>
    )
  );
};

export default FavoriteArticles;
