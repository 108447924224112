import { message } from 'antd';

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = file => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('Vous ne pouvez télécharger que des fichiers JPG/PNG !');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("La taille de l'image doit être inférieure à 2 Mo !");
  }
  return isJpgOrPng && isLt2M;
};

export { getBase64, beforeUpload };
