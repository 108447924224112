import React from 'react';
import { Card } from 'antd';
import FeatherIcon from 'feather-icons-react';

const ErrorAPIMessage = () => {
  return (
    <Card className="m-2 center">
      <div className="d-flex align-items-center justify-content-center color-error bold mb-1">
        <FeatherIcon icon="alert-circle" className="mr-50" size={18} />
        Une erreur est survenue
        <FeatherIcon icon="alert-circle" className="ml-50" size={18} />
      </div>
      Désolé, une erreur est survenue. Veuillez nous excuser pour le désagrément occasionné.
      <br />
      Nous travaillons à résoudre le problème. Veuillez réessayer ultérieurement.
    </Card>
  );
};

export default ErrorAPIMessage;
