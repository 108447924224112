import { createSlice } from '@reduxjs/toolkit';
import {
  createBlog,
  deleteBLog,
  editBlog,
  editImageBlog,
  getOneBlog,
  getSpotlightBlog,
  editSpotlightBlog,
  filterBlog,
  getFavoriteArticles,
  filterBlogByDate,
} from './blogAsyncThunk';

const dataStatusObject = {
  data: null,
  isLoading: false,
  isError: false,
  isSuccess: false,
};

const initialState = {
  favoriteArticles: dataStatusObject,
  articles: null,
  filters: {
    query: '',
    date: {
      startDate: null,
      endDate: null,
    },
  },

  getblog: {
    articles: [],
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  },

  createBlog: {
    articles: [],
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  },

  deleteBlog: {
    articles: [],
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  },

  updateBlog: {
    articles: [],
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  },

  getSingleBlog: dataStatusObject,

  updateImageBlog: dataStatusObject,

  createSpotlightArticle: dataStatusObject,

  getSpotlightArticle: dataStatusObject,

  editSpotlightArticle: dataStatusObject,
};

export const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    resetCreateBlogSuccess: state => {
      state.createBlog.isSuccess = false;
    },
    resetEditBlogSuccess: state => {
      state.updateBlog.isSuccess = false;
    },
    resetDeleteBlogSuccess: state => {
      state.deleteBlog.isSuccess = false;
    },
    clearBlogData: state => {
      state.getSingleBlog.data = null;
    },
    setBlogFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
  extraReducers: builder => {
    builder
      // get favorite articles
      .addCase(getFavoriteArticles.pending, state => {
        state.favoriteArticles.isLoading = true;
      })
      .addCase(getFavoriteArticles.fulfilled, (state, action) => {
        state.favoriteArticles.isLoading = false;
        state.favoriteArticles.data = action.payload;
      })
      .addCase(getFavoriteArticles.rejected, (state, action) => {
        state.favoriteArticles.isLoading = false;
        state.favoriteArticles.isError = action.payload;
      })

      //createBlog
      .addCase(createBlog.pending, state => {
        state.createBlog.isLoading = true;
        state.createBlog.isSuccess = false;
      })
      .addCase(createBlog.fulfilled, (state, action) => {
        state.createBlog.isLoading = false;
        state.createBlog.isSuccess = true;
        state.createBlog.articles.shift(action.payload);
      })
      .addCase(createBlog.rejected, (state, action) => {
        state.createBlog.isLoading = false;
        state.createBlog.isSuccess = false;
        state.createBlog.errorMessage = action.payload;
      })

      //deleteBlog
      .addCase(deleteBLog.pending, state => {
        state.deleteBlog.isLoading = true;
        state.deleteBlog.isSuccess = false;
      })
      .addCase(deleteBLog.fulfilled, (state, action) => {
        state.deleteBlog.isLoading = false;
        state.deleteBlog.isSuccess = true;
        state.deleteBlog.articles = state.deleteBlog.articles.filter(
          blog => blog.id !== action.payload
        );
      })
      .addCase(deleteBLog.rejected, (state, action) => {
        state.deleteBlog.isLoading = false;
        state.deleteBlog.isSuccess = false;
        state.deleteBlog.errorMessage = action.payload;
      })

      //updateBlog
      .addCase(editBlog.pending, state => {
        state.updateBlog.isLoading = true;
      })
      .addCase(editBlog.fulfilled, (state, action) => {
        state.updateBlog.isLoading = false;
        state.updateBlog.isSuccess = true;
        state.updateBlog.articles = action.payload;
      })
      .addCase(editBlog.rejected, (state, action) => {
        state.updateBlog.isLoading = false;
        state.updateBlog.isSuccess = false;
        state.updateBlog.errorMessage = action.payload;
      })

      //updateImageBlog
      .addCase(editImageBlog.pending, state => {
        state.updateImageBlog.isLoading = true;
      })
      .addCase(editImageBlog.fulfilled, (state, action) => {
        state.updateImageBlog.isLoading = false;
        state.updateImageBlog.isSuccess = true;
        state.updateImageBlog.data = action.payload;
      })
      .addCase(editImageBlog.rejected, (state, action) => {
        state.updateImageBlog.isLoading = false;
        state.updateImageBlog.isSuccess = false;
        state.updateImageBlog.errorMessage = action.payload;
      })

      //getOneBlog
      .addCase(getOneBlog.pending, state => {
        state.getSingleBlog.isLoading = true;
      })
      .addCase(getOneBlog.fulfilled, (state, action) => {
        state.getSingleBlog.isLoading = false;
        state.getSingleBlog.isSuccess = true;
        state.getSingleBlog.data = action.payload;
      })
      .addCase(getOneBlog.rejected, (state, action) => {
        state.getSingleBlog.isLoading = false;
        state.getSingleBlog.isSuccess = false;
        state.getSingleBlog.errorMessage = action.payload;
      })

      //getSpotlightBlog
      .addCase(getSpotlightBlog.pending, state => {
        state.getSpotlightArticle.isLoading = true;
      })
      .addCase(getSpotlightBlog.fulfilled, (state, action) => {
        state.getSpotlightArticle.isLoading = false;
        state.getSpotlightArticle.isSuccess = true;
        state.getSpotlightArticle.data = action.payload;
      })
      .addCase(getSpotlightBlog.rejected, (state, action) => {
        state.getSpotlightArticle.isLoading = false;
        state.getSpotlightArticle.isSuccess = false;
        state.getSpotlightArticle.errorMessage = action.payload;
      })

      //editSpotlightBlog
      .addCase(editSpotlightBlog.pending, state => {
        state.editSpotlightArticle.isLoading = true;
      })
      .addCase(editSpotlightBlog.fulfilled, (state, action) => {
        state.editSpotlightArticle.isLoading = false;
        state.editSpotlightArticle.isSuccess = true;
        state.editSpotlightArticle.data = action.payload;
      })
      .addCase(editSpotlightBlog.rejected, (state, action) => {
        state.editSpotlightArticle.isLoading = false;
        state.editSpotlightArticle.isSuccess = false;
        state.editSpotlightArticle.errorMessage = action.payload;
      })

      //filterBlog
      .addCase(filterBlog.pending, state => {
        state.isLoading = true;
      })
      .addCase(filterBlog.fulfilled, (state, action) => {
        state.isLoading = false;
        state.articles = action.payload;
      })

      //filter Blog By Date
      .addCase(filterBlogByDate.pending, state => {
        state.isLoading = true;
      })
      .addCase(filterBlogByDate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.articles = action.payload;
      })
      .addCase(filterBlogByDate.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const {
  addArticle,
  resetCreateBlogSuccess,
  resetEditBlogSuccess,
  clearBlogData,
  resetDeleteBlogSuccess,
  setBlogFilters,
} = blogSlice.actions;

export default blogSlice.reducer;
