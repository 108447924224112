import Styled from 'styled-components';

const AdminItemsContainer = Styled.div`
  margin: 2rem;

  .admins_list{
    colgroup{
      width: 94%;
    }
  }
`;

const AdminItemContainer = Styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-weight: 600;
  display: flex;
  background-color: white;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    &:hover {
    transform: scale(1.01);
    }
`;

const AdminListHeaderContainer = Styled.div`
  display: flex;
  align-items: center;
  justify-content:space-between;
  margin: 2rem;
  margin-top: 0;

  @media only screen and (max-width: 599px){
    flex-direction: column;
  }
`;

export { AdminItemsContainer, AdminItemContainer, AdminListHeaderContainer };
