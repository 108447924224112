import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Card, Row, Col, Button, Tooltip } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { ArticleCard } from 'container/BlogContainer/blog/style';
import blogPlaceholderImage from 'assets/image/placeholder-image.jpg';

import { isoDateToCustomDate } from 'utils/conversion';
import { setFeaturedArticlesModal } from 'redux/slices/blog/featured-articles/featuredArticlesSlice';

const ArticleCardItem = ({ title, image, author, id, date, data }) => {
  const dispatch = useDispatch();

  const [blogImage, setBlogImage] = useState('');

  const onOpenDetailsModalHandler = () =>
    dispatch(setFeaturedArticlesModal({ modalRef: 'edit-featured-article', data }));

  const onOpenDeleteModalHandler = () =>
    dispatch(setFeaturedArticlesModal({ modalRef: 'delete-article', id }));

  useEffect(() => {
    setBlogImage(image?.replace(/^http:\/\//i, 'https://'));
  }, [image]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ArticleCard className="article-card">
      <Row justify="center">
        <Col>
          <Card className="list-card">
            <img
              src={blogImage ? blogImage : blogPlaceholderImage}
              alt="article"
              className="blogCardImage"
            />
            {blogImage ? '' : <p className="placeholder_img_text">PAS D&apos;IMAGE</p>}
            <div className="content_container">
              <h2 className="cardTitle">{title}</h2>
              <p className="article_date">Date de Publication: {isoDateToCustomDate(date)}</p>

              <p>{author}</p>

              <Row justify="space-between" align="middle">
                <Col className="cardButtons">
                  <Button onClick={onOpenDeleteModalHandler}>
                    <Tooltip title="Supprimer" showArrow={false}>
                      <FeatherIcon icon="trash-2" size={18} />
                    </Tooltip>
                  </Button>

                  <Button onClick={onOpenDetailsModalHandler}>
                    <Tooltip title="Modifier" showArrow={false}>
                      <FeatherIcon icon="edit" size={18} />
                    </Tooltip>
                  </Button>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </ArticleCard>
  );
};

export default ArticleCardItem;
