import React from 'react';

import { ActionsContainer } from 'container/styled';

import { Link } from 'react-router-dom';

import { Tooltip } from 'antd';

const Actions = ({ tableRow }) => {
  return (
    <ActionsContainer>
      <Tooltip title="Modifier" showArrow={false}>
        <Link to={`/admin/edit-auto-ecoles/${tableRow.slug}`}>
          <i className="icon-edit mr-10" />
        </Link>
      </Tooltip>
    </ActionsContainer>
  );
};

export default Actions;
