import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table } from 'antd';
import Status from './Status';
import { Cards } from 'components/cards/frame/cards-frame';

import { getSchoolsRequests } from 'redux/slices/driving-school/schoolAsyncThunk';
import { columns } from 'redux/slices/driving-school/dataTable';

import { TableContainer } from '../styled';
import Actions from './Actions';

const DrivingSchoolsTable = () => {
  const dispatch = useDispatch();

  const { filter } = useSelector(state => state.drivingSchool);
  const data = useSelector(state => state?.drivingSchool?.requests);

  const [currentPage, setCurrentPage] = useState(1);

  const totalData = data?.num_totals_items;

  const handlePageChange = page => {
    setCurrentPage(page);
    dispatch(getSchoolsRequests({ filter, page }));
  };

  const dataSource = data?.items.map((tableRow, index) => {
    const { key = index, id, email, nomCommercial, phone, firstName, lastName, slug } = tableRow;
    const fullName = firstName || lastName ? `${firstName || '---'} ${lastName || '---'}` : '---';

    return {
      key,
      id,
      email: email || '---',
      nomCommercial: nomCommercial || '---',
      phone: phone || '---',
      firstName: fullName,
      slug,
      actions: <Actions currentPage={currentPage} data={data} tableRow={tableRow} />,
      status: <Status currentPage={currentPage} tableRow={tableRow} />,
    };
  });

  return (
    <TableContainer>
      <Cards headless>
        <Table
          className="table-responsive"
          pagination={{
            current: currentPage,
            total: totalData,
            defaultPageSize: 10,
            onChange: handlePageChange,
          }}
          dataSource={dataSource}
          columns={columns}
        />
      </Cards>
    </TableContainer>
  );
};

export default DrivingSchoolsTable;
