import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_HOST, API } from 'config/api';
import axiosService from 'services/axiosService';

export const getDemandList = createAsyncThunk(
  'drivingSchool/getDemandList',
  async (page, { fulfillWithValue, rejectWithValue }) => {
    const URL = `${API_HOST + API.request.list}?page=${page}`;

    return axiosService
      .get(URL)
      .then(res => fulfillWithValue(res.data))
      .catch(error => rejectWithValue(error.message));
  }
);

export const filtreRequestList = createAsyncThunk(
  'drivingSchool/filtreRequestList',
  async (query, { rejectWithValue }) => {
    const URL = API_HOST + API.request.filtre;
    try {
      const response = await axiosService.post(URL, query);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
