import React from 'react';
import propTypes from 'prop-types';
import { Route } from 'react-router-dom';

const ProtectedRoute = ({ component, path }) => {
  return <Route component={component} path={path} />
};

ProtectedRoute.propTypes = {
  component: propTypes.object.isRequired,
  path: propTypes.string.isRequired,
};

export default ProtectedRoute;
