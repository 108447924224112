const convertUrlHttps = url => {
  if (url?.startsWith('http://')) {
    url = 'https://' + url.slice(7);
  }
  return url;
};

const isoDateToCustomDate = isoDate => {
  if (isoDate) {
    const date = new Date(isoDate).toLocaleDateString('fr-FR')
    return date;
  } else {
    return `--/--/--`;
  }
};

export { convertUrlHttps, isoDateToCustomDate };
