import React from 'react';

import { Space, Spin } from 'antd';
import { LoaderContainer } from './styled';

const LoadingPage = ({ headerHeight }) => {
  return (
    <LoaderContainer headerHeight={headerHeight}>
      <Space direction="vertical" className="spinner">
        <Space className="d-flex align-items-center">
          <span>
            <Spin className="spin mr-5">
              <div className="content" />
            </Spin>
          </span>

          <span className="ml-1 bold color-primary">Loading</span>
        </Space>
      </Space>
    </LoaderContainer>
  );
};

export default LoadingPage;

LoadingPage.defaultProps = {
  headerHeight: '0px',
};
