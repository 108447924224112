import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ArticleCardItem from './ArticleCardItem';
import SpotlightBlogModal from './modals/SpotlightBlogModal';
import { Card, Pagination } from 'antd';
import DeleteSpotlightBlogModal from './modals/DeleteSpotlightBlogModal';

import { getFeaturedArticles } from 'redux/slices/blog/featured-articles/featuredArticlesThunks';

import { ArticlesContainer } from '../style';

const SpotlightBlogList = ({ data }) => {
  const dispatch = useDispatch();

  const { filter } = useSelector(state => state.featuredArticles);

  return (
    <>
      <h2 className="mt-2 mb-1">Articles</h2>
      {data?.items?.length > 0 ? (
        <>
          <ArticlesContainer>
            {data?.items?.map(el => (
              <ArticleCardItem
                key={el.id}
                title={el.title}
                image={el.imageArticle}
                content={el.description}
                author={el.author}
                id={el.id}
                date={el.createdAt}
                data={el}
              />
            ))}
          </ArticlesContainer>
          <Pagination
            current={data?.current_page_number}
            total={data?.num_totals_items}
            onChange={page => dispatch(getFeaturedArticles({ filter, page }))}
          />
        </>
      ) : (
        <Card>Aucun résultat trouvé</Card>
      )}

      {/* modal */}
      <SpotlightBlogModal />
      <DeleteSpotlightBlogModal />
    </>
  );
};

export default SpotlightBlogList;
