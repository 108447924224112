import { createSlice } from '@reduxjs/toolkit';

import { loginThunk } from './authAsyncThunk';
import { getToken } from 'services/authenticationService';

const token = getToken('token');

const initialState = {
  isLoading: false,
  isError: false,
  isLoggedIn: token,
  token: token || null,
  message: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOut: state => {
      state.loginThunk = false;
      state.isLoggedIn = false;
      state.token = null;
      state.isLoading = false;
      state.isError = false;
    },
    resetErrorState: state => {
      state.isError = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loginThunk.pending, state => {
        state.isLoading = true;
      })
      .addCase(loginThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = true;
        state.token = action.payload;
      })
      .addCase(loginThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.token = null;
      });
  },
});

// Action creators are generated for each case reducer function
export const { logOut, resetErrorState } = authSlice.actions;

export default authSlice.reducer;
