import React from 'react';

import { Button, Spin } from 'antd';

const SubmitButton = ({
  text,
  type,
  isLoading,
  disabled,
  htmlType,
  spinClassName,
  onClick,
  className,
}) => {
  return (
    <Button
      type={type}
      onClick={onClick}
      disabled={disabled}
      htmlType={htmlType}
      className={className}
    >
      {text}
      {isLoading && <Spin size="small" className={spinClassName} />}
    </Button>
  );
};

export default SubmitButton;
