import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API, API_HOST } from 'config/api';
import axiosService from 'services/axiosService';

export const getFeaturedArticles = createAsyncThunk(
  'getFeaturedArticles',
  async ({ filter, page }, { fulfillWithValue, rejectWithValue }) => {
    const URL = API_HOST + API.spotlightBlog.list(page);

    return axiosService
      .post(URL, filter)
      .then(res => fulfillWithValue(res.data))
      .catch(err => rejectWithValue(err.message));
  }
);

export const getFeaturedArticle = createAsyncThunk(
  'getFeaturedArticle',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    const URL = API_HOST + API.spotlightBlog.featuredArticle;

    return axios
      .get(URL)
      .then(res => fulfillWithValue(res.data.data[0]))
      .catch(err => rejectWithValue(err.message));
  }
);

export const createFeaturedArticle = createAsyncThunk(
  'createFeaturedArticle',
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const URL = API_HOST + API.spotlightBlog.create;

    return axiosService
      .post(URL, data)
      .then(res => fulfillWithValue(res.data.data[0]))
      .catch(err => rejectWithValue(err.message));
  }
);

export const editFeaturedArticle = createAsyncThunk(
  'editFeaturedArticle',
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const URL = API_HOST + API.spotlightBlog.edit(data.id);

    return axiosService
      .post(URL, data.data)
      .then(res => fulfillWithValue(res.data.data))
      .catch(err => rejectWithValue(err.message));
  }
);

export const deleteFeaturedArticle = createAsyncThunk(
  'deleteFeaturedArticle',
  async (id, { rejectWithValue, dispatch }) => {
    const URL = API_HOST + API.spotlightBlog.delete(id);

    return axiosService
      .delete(URL)
      .then(() =>
        dispatch(
          getFeaturedArticles({ filter: { search: null, startDate: null, endDate: null }, page: 1 })
        )
      )
      .catch(err => rejectWithValue(err.message));
  }
);
