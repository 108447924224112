import { createSlice } from '@reduxjs/toolkit';
import { filtreRequestList, getDemandList } from './requestDemandAsyncThunk';
import { demandColumns } from './dataTable';

const initialState = {
  demandColumns,
  getDemandLIst: {
    data: null,
    isLoading: false,
    isError: false,
    isSuccess: false,
  },

  searchRequest: null,
};

export const demandSlice = createSlice({
  name: 'demandList',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      //getList
      .addCase(getDemandList.pending, state => {
        state.getDemandLIst.isLoading = true;
      })
      .addCase(getDemandList.fulfilled, (state, action) => {
        state.getDemandLIst.isLoading = false;
        state.searchRequest = action.payload;
      })
      .addCase(getDemandList.rejected, (state, action) => {
        state.getDemandLIst.isLoading = false;
        state.getDemandLIst.isError = action.payload;
      })

      //filterList
      .addCase(filtreRequestList.fulfilled, (state, action) => {
        state.searchRequest = action.payload;
      });
  },
});

export default demandSlice.reducer;
