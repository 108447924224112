import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'antd';

import { resetAdminListModal } from 'redux/slices/adminList/adminListSlice';

const ErrorModal = () => {
  const dispatch = useDispatch();

  const modal = useSelector(state => state.adminList.modal);

  const handleCancel = () => dispatch(resetAdminListModal());

  return (
    <Modal
      title="Oups, quelque chose s'est mal passé !"
      open={modal.modalRef === 'error'}
      onCancel={handleCancel}
      footer={null}
      centered
    >
      Une erreur s'est produite lors du traitement de votre demande. Veuillez réessayer plus tard.
    </Modal>
  );
};

export default ErrorModal;
