import { createSlice } from '@reduxjs/toolkit';
import {
  createFeaturedArticle,
  deleteFeaturedArticle,
  editFeaturedArticle,
  getFeaturedArticle,
  getFeaturedArticles,
} from './featuredArticlesThunks';

const dataStatusObject = {
  data: null,
  isLoading: false,
  error: false,
};

const initialState = {
  list: dataStatusObject,
  featuredArticle: dataStatusObject,

  modal: { modalRef: null, isLoading: false },

  filter: { search: null, startDate: null, endDate: null },
};

export const featuredArticlesSlice = createSlice({
  name: 'featured-articles',
  initialState,
  reducers: {
    setFeaturedArticlesModal: (state, action) => {
      state.modal = { ...state.modal, ...action.payload };
    },
    resetFeaturedArticlesModal: state => {
      state.modal = { modalRef: null, isLoading: false };
    },
    setFilters: (state, action) => {
      state.filter = { ...state.filter, ...action.payload };
    },
  },
  extraReducers: builder => {
    builder

      //get featured articles
      .addCase(getFeaturedArticles.pending, state => {
        state.list.isLoading = true;
      })
      .addCase(getFeaturedArticles.fulfilled, (state, action) => {
        state.list.isLoading = false;
        state.list.data = action.payload;
      })
      .addCase(getFeaturedArticles.rejected, (state, action) => {
        state.list.isLoading = false;
        state.list.error = action.payload;
      })

      //get featured article
      .addCase(getFeaturedArticle.pending, state => {
        state.featuredArticle.isLoading = true;
      })
      .addCase(getFeaturedArticle.fulfilled, (state, action) => {
        state.featuredArticle.isLoading = false;
        state.featuredArticle.data = action.payload;
      })
      .addCase(getFeaturedArticle.rejected, (state, action) => {
        state.featuredArticle.isLoading = false;
        state.featuredArticle.error = action.payload;
      })

      //add new featured article
      .addCase(createFeaturedArticle.pending, state => {
        state.modal.isLoading = true;
      })
      .addCase(createFeaturedArticle.fulfilled, (state, action) => {
        state.modal = { modalRef: null, isLoading: false };

        if (action.payload.publish) state.featuredArticle.data = action.payload;
      })
      .addCase(createFeaturedArticle.rejected, (state, action) => {
        state.modal = { modalRef: null, isLoading: false };
        state.featuredArticle.error = action.payload;
      })

      //edit new featured article
      .addCase(editFeaturedArticle.pending, state => {
        state.modal.isLoading = true;
      })
      .addCase(editFeaturedArticle.fulfilled, (state, action) => {
        state.modal = { modalRef: null, isLoading: false };

        if (action.payload.publish) state.featuredArticle.data = action.payload;
      })
      .addCase(editFeaturedArticle.rejected, (state, action) => {
        state.modal = { modalRef: null, isLoading: false };
        state.featuredArticle.error = action.payload;
      })

      //delete featured article
      .addCase(deleteFeaturedArticle.pending, state => {
        state.modal.isLoading = true;
      })
      .addCase(deleteFeaturedArticle.fulfilled, state => {
        state.modal = { modalRef: null, isLoading: false };
      })
      .addCase(deleteFeaturedArticle.rejected, (state, action) => {
        state.modal = { modalRef: null, isLoading: false };
        state.featuredArticle.error = action.payload;
      });
  },
});

export const {
  setFeaturedArticlesModal,
  resetFeaturedArticlesModal,
  setFilters,
} = featuredArticlesSlice.actions;

export default featuredArticlesSlice.reducer;
