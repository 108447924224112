import { configureStore } from '@reduxjs/toolkit';

// Slices
import adminList from './slices/adminList/adminListSlice';
import auth from './slices/auth/authSlice';
import blog from './slices/blog/articles/blogSlice';
import chart from './slices/chart/chartSlice';
import dashboard from './slices/dashboard/dashboardSlice';
import demandList from './slices/request/requestDemandSlice';
import drivingSchool from './slices/driving-school/drivingSchoolSlice';
import featuredArticles from './slices/blog/featured-articles/featuredArticlesSlice';
import layout from './slices/layout/layoutSlice';
import modal from './slices/modal/modalSlice';
import permit from './slices/permit/permitSlice';
import vehicules from './slices/vehicule-type/vehiculeTypeSlice';
import serviceType from './slices/serviceType/serviceTypeSlice';
import settings from './slices/settings/settingsSlice';

// The reducers should be listed alphabetically to make it easier to find them.
// Please keep this order when adding or removing reducers.

export const store = configureStore({
  reducer: {
    adminList,
    auth,
    blog,
    chart,
    dashboard,
    drivingSchool,
    featuredArticles,
    layout,
    modal,
    permit,
    vehicules,
    serviceType,
    settings,
    demandList,
  },
});
