import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_HOST, API } from 'config/api';
import axiosService from 'services/axiosService';

export const adminSettings = createAsyncThunk(
  'settings/adminSettings',
  async (id, { fulfillWithValue, rejectWithValue }) => {
    const URL = API_HOST + API.settings.adminSettings(id);

    return axiosService
      .get(URL)
      .then(res => fulfillWithValue(res.data))
      .catch(error => rejectWithValue(error));
  }
);

export const editSettings = createAsyncThunk(
  'settings/editSettings',
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const URL = API_HOST + API.settings.edit;

    return axiosService
      .patch(URL, data)
      .then(res => fulfillWithValue(res.data))
      .catch(error => rejectWithValue(error));
  }
);

export const getUnidriveLogo = createAsyncThunk(
  'settings/getUnidriveLogo',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    const URL = API_HOST + API.settings.logo;

    return axios
      .get(URL)
      .then(res => fulfillWithValue(res.data.lienLogo))
      .catch(error => rejectWithValue(error));
  }
);

export const getTags = createAsyncThunk(
  'settings/getTags',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    const URL = API_HOST + API.settings.tags.list;

    return axiosService
      .get(URL)
      .then(res => fulfillWithValue(res.data))
      .catch(error => rejectWithValue(error));
  }
);

export const addTag = createAsyncThunk(
  'settings/addTag',
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const URL = API_HOST + API.settings.tags.add;

    return axiosService
      .post(URL, data)
      .then(res => fulfillWithValue(res.data))
      .catch(error => rejectWithValue(error));
  }
);

export const deleteTag = createAsyncThunk(
  'settings/deleteTag',
  async (id, { fulfillWithValue, rejectWithValue }) => {
    const URL = API_HOST + API.settings.tags.delete(id);

    return axiosService
      .delete(URL)
      .then(() => fulfillWithValue(id))
      .catch(error => rejectWithValue(error));
  }
);

export const editTag = createAsyncThunk(
  'settings/editTag',
  async ({ id, nameCategory }, { fulfillWithValue, rejectWithValue }) => {
    const URL = API_HOST + API.settings.tags.edit(id);

    return axiosService
      .put(URL, { nameCategory })
      .then(() => fulfillWithValue(id))
      .catch(error => rejectWithValue(error));
  }
);
