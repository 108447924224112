import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SpotlightBlogHeader from './components/SpotlightBlogHeader';
import LoadingPage from 'components/UI/Loader/LoadingPage';
import SpotlightBlogList from './components/SpotlightBlogList';
import SpotlightBlogArticle from './components/SpotlightBlogArticle';

import {
  getFeaturedArticle,
  getFeaturedArticles,
} from 'redux/slices/blog/featured-articles/featuredArticlesThunks';

import { SpotlightArticle } from './style';

const SpotlightBlog = () => {
  const dispatch = useDispatch();

  const {
    list: { data, isLoading },
    filter,
  } = useSelector(state => state.featuredArticles);

  useEffect(() => {
    dispatch(getFeaturedArticles({ filter, page: 1 }));
    dispatch(getFeaturedArticle());
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SpotlightArticle>
      <SpotlightBlogHeader />

      {isLoading ? (
        <LoadingPage />
      ) : (
        <>
          <SpotlightBlogArticle />
          <SpotlightBlogList data={data} filter={filter} />
        </>
      )}
    </SpotlightArticle>
  );
};

export default SpotlightBlog;
