import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TablePageHeader from 'components/Table/TablePageHeader';
import { getSchoolsRequests } from 'redux/slices/driving-school/schoolAsyncThunk';
import { filterDrivingSchool } from 'redux/slices/driving-school/drivingSchoolSlice';

const DrivingSchoolTablePageHeader = () => {
  const dispatch = useDispatch();

  const schoolData = useSelector(state => state?.drivingSchool?.requests);
  const filter = useSelector(state => state.drivingSchool.filter);

  const result = schoolData?.num_totals_items !== 1 ? 'Demandes' : 'Demande';
  const totalPerPage = (schoolData?.current_page_number - 1) * schoolData?.num_items_per_page + 1;
  const totalShownItems = Math.min(
    totalPerPage + schoolData?.num_items_per_page - 1,
    schoolData?.num_totals_items
  );

  const filterByCommercialNameOrEmailOrName = event => {
    const nameCommercialOrEmailOrName = event.target.value;

    dispatch(filterDrivingSchool({ nameCommercialOrEmailOrName }));
    dispatch(getSchoolsRequests({ filter: { ...filter, nameCommercialOrEmailOrName }, page: 1 }));
  };

  useEffect(() => {
    dispatch(getSchoolsRequests({ page: 1 }));
  }, [dispatch]);

  return (
    <TablePageHeader
      title="Demandes de vérification"
      result={result}
      totalPerPage={totalPerPage}
      totalShownItems={totalShownItems}
      total={schoolData?.num_totals_items}
      placeholder="nom commercial/email"
      searchInputOnChange={filterByCommercialNameOrEmailOrName}
      searchInputValue={filter.nameCommercialOrEmailOrName}
    />
  );
};

export default DrivingSchoolTablePageHeader;
