import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TablePageHeader from 'components/Table/TablePageHeader';
import { DatePicker } from 'antd';

import { setBlogFilters } from 'redux/slices/blog/articles/blogSlice';
import { filterBlog, filterBlogByDate } from 'redux/slices/blog/articles/blogAsyncThunk';

const BlogPageHeader = ({ showModalHandler }) => {
  const dispatch = useDispatch();

  const [totalArticle, setTotalArticle] = useState(0);

  const {
    articles,
    filters: { query },
  } = useSelector(state => state.blog);

  const result = articles?.num_totals_items !== 1 ? ' Articles ' : ' Article ';
  const totalPerPage = (articles?.current_page_number - 1) * articles?.num_items_per_page + 1;
  const totalShownItems = Math.min(
    totalPerPage + articles?.num_items_per_page - 1,
    articles?.num_totals_items
  );

  const searchInputOnChange = event => {
    dispatch(setBlogFilters({ query: event.target.value }));
    dispatch(filterBlog({ page: 1, query: event.target.value }));
  };

  const selectDate = (_, dateString) => {
    const startDate = dateString[0];
    const endDate = dateString[1];
    const date = dateString[0] !== '' || dateString[1] !== '' ? { startDate, endDate } : undefined;

    dispatch(setBlogFilters(date));
    date ? dispatch(filterBlogByDate({ page: 1, date })) : dispatch(filterBlog({ page: 1, query }));
  };

  useEffect(() => {
    if (articles) {
      setTotalArticle(articles?.num_totals_items);
    }
  }, [articles]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TablePageHeader
      title="Article"
      result={result}
      totalPerPage={totalPerPage}
      total={totalArticle}
      totalShownItems={totalShownItems}
      onButtonClick={showModalHandler}
      placeholder="Recherche par titre et auteur"
      searchInputValue={query}
      showButton
      buttonText="Nouvel article"
      searchInputOnChange={searchInputOnChange}
      otherInputsAfterSearchInput={
        <div className="align-center-v filter_container">
          <span className="table-result filter_text">Filtrer par:</span>
          <DatePicker.RangePicker
            format="YYYY-MM-DD"
            onChange={selectDate}
            placeholder={['Date de début', 'Date de fin']}
          />
        </div>
      }
    />
  );
};

export default BlogPageHeader;
