import Styled from 'styled-components';

const FavoriteButton = Styled.div`
    @keyframes fillAnimation {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.5);
      }
      100% {
        transform: scale(1);
      }
    }

  .heart-button{
    cursor: pointer;
    
    &.liked svg{
      animation: fillAnimation 0.5s linear;
      fill: red;
      color: red;
    }
  }

`;

const BlogContainer = Styled.div`
    margin: 0 30px 30px;
    height: 100%;

    @media screen and (max-width: 768px){
        margin-top: 60px;
    }

    @media screen and (max-width: 767px){
        margin-top: 20px;
    }
    
    .chart-container{
        margin-top: 25px;
      }

      .spinner{
        width: 100%;
        height: 100vh;
        display: flex !important;
        justify-content: center;
        align-items: center;
          .ant-spin-text{
            margin-left: -25px;
          }
      }
`;

const ArticlesContainer = Styled.div`

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-gap: 1rem;
    margin-bottom: 1rem;
    

    .cardButtons{
      display: flex;
    }

    @media only screen and (max-width: 1024px){
      justify-content: center;
    }

    @media only screen and (max-width: 599px){
      flex-direction: column;
      flex-wrap: unwrap;
      align-items: center;
    }

    .list-card {
      overflow: hidden !important;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.03);
      width: 280px;

      .content_container{
        padding: 12px;

          .card_content{
            p,h1,h2,pre{
              margin-bottom: 0.5rem;
              width: 250px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .LinesEllipsis-ellipsis{
              display: none;
            } 
          }

        p{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .article_date{
          font-size:12px;
        }
      }

      p:nth-of-type(2){
        font-size: 13px;
        color: #747474;
        margin-top: -.5rem;
      }

      &:hover {
        box-shadow: 0px 4px 24px #0000001c;
        transition: ease-in-out 0.5s;
      }

      .cardTitle,.cardContent{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 0.5rem;
      }

      .blogCardImage{
        height: 160px;
        object-fit: cover;
      }
    }


    h2 {
        font-size: 16px;
        margin-bottom: 1rem ;
        text-align: left;
    }

    .ant-card-body {
      padding: 0 !important; 
    }
`;

const ArticleCard = Styled.div`
    button{
      padding: 5px 12px 0 !important;
    }

    img {
     object-fit: cover;
     width: -webkit-fill-available; 
    }
    .content {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1; 
      -webkit-box-orient: vertical;
      white-space: break-spaces; 
      margin-bottom: 1rem;
    }
    .placeholder_img_text{
      position: absolute;
      z-index: 10;
      top: 40%;
      left: 30%;
      font-weight: 700;
      letter-spacing: 2px;
      color: #818181;
      font-size: 16px;
    }
`;

const PaginationContainer = Styled.div`
    button{
      display: flex;
    }
`;

export { BlogContainer, ArticlesContainer, ArticleCard, PaginationContainer, FavoriteButton };
