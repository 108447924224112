import Styled from 'styled-components';

const ModalWrapper = Styled.div`
  .input-container{
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
`;

const TableContainer = Styled.div`
    .ant-pagination-options{
      display: none;
    }
`;

const StatusContainer = Styled.div`
  display: flex;
  align-items: center;

  .feather-check,
  .feather-x {
    cursor: pointer;
  }

  .feather-check{
    color: #72A98F;
  }

  .feather-x{
    color: #DB5461;
  }

  button{
    box-shadow: inherit;
    padding: 0 !important;
  }
`;
export { ModalWrapper, TableContainer, StatusContainer };
