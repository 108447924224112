import React from 'react';
import DrivingSchoolTablePageHeader from './header';
import DrivingSchoolsTable from './table';

const DrivingSchoolPage = () => {
  return (
    <>
      <DrivingSchoolTablePageHeader />
      <DrivingSchoolsTable />
    </>
  );
};

export default DrivingSchoolPage;
