import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AdminListHeader from './list/AdminListHeader';
import AdminList from './list/AdminList';
import AddEditAdminModal from './modals/AddEditAdminModal';
import DeleteAdminModal from './modals/DeleteAdminModal';
import LoadingPage from 'components/UI/Loader/LoadingPage';
import ErrorModal from './modals/ErrorModal';

import { getAdminsList } from 'redux/slices/adminList/adminListAsyncThunk';
import ErrorAPIMessage from 'components/UI/Errors/ErrorAPIMessage';

const AdminsListPage = () => {
  const dispatch = useDispatch();

  const { isLoading, error } = useSelector(state => state.adminList);

  useEffect(() => {
    dispatch(getAdminsList());
  }, [dispatch]);

  return (
    <>
      <AdminListHeader />

      {isLoading ? (
        <LoadingPage headerHeight="168px" />
      ) : error ? (
        <ErrorAPIMessage />
      ) : (
        <AdminList />
      )}

      {/* Modals */}
      <AddEditAdminModal />
      <DeleteAdminModal />
      <ErrorModal />
    </>
  );
};

export default AdminsListPage;
