import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_HOST, API } from 'config/api';
import axiosService from 'services/axiosService';

export const createPermit = createAsyncThunk(
  'permit/createPermit',
  async (data, { rejectWithValue }) => {
    const URL = API_HOST + API.permit.create;
    try {
      return await axiosService.post(`${URL}`, data);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPermit = createAsyncThunk(
  'permit/getPermit',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    const URL = API_HOST + API.permit.list;

    return axios
      .get(URL)
      .then(res =>
        fulfillWithValue(
          res.data.map(item => {
            return {
              label: item.nameType,
              value: item.id,
            };
          })
        )
      )
      .catch(err => rejectWithValue(err.message));
  }
);
