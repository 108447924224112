import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FeatherIcon from 'feather-icons-react';
import { StatusContainer } from '../styled';
import { Button, Spin, Tag, Tooltip, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { approveSchool } from 'redux/slices/driving-school/schoolAsyncThunk';

const Status = ({ tableRow, currentPage }) => {
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const { filter } = useSelector(state => state.drivingSchool);

  const [isLoading, setIsLoading] = useState(false);

  const schoolStatus = tableRow.isVerifiedByAdmin;

  const antIcon = <LoadingOutlined size={14} spin />;

  const openNotification = (type, action) => {
    let description = '';
    if (action === 'approved') {
      description = `L'auto école est approuvée`;
    } else if (action === 'rejected') {
      description = `L'auto école est rejetée`;
    } else {
      description = `Une erreur s'est produite`;
    }
    api[type]({
      description,
      duration: 3,
    });
  };

  const approveSchoolStatus = async isVerifiedByAdmin => {
    setIsLoading(true);
    
    dispatch(approveSchool({ id: tableRow.id, isVerifiedByAdmin, filter, page: currentPage })).then(
      res => {
        setIsLoading(false);

        res.error
          ? openNotification('error')
          : openNotification('success', isVerifiedByAdmin ? 'approved' : 'rejected');
      }
    );
  };

  return (
    <StatusContainer>
      {contextHolder}
      {isLoading ? (
        <Spin indicator={antIcon} className="m-a" />
      ) : schoolStatus ? (
        <Tag color="#72A98F">Approuvé</Tag>
      ) : (
        <>
          {schoolStatus === false ? (
            <Tag color="#DB5461">Rejeté</Tag>
          ) : (
            <>
              <div>
                <Tooltip title="Approuvé" showArrow={false}>
                  <Button ghost onClick={() => approveSchoolStatus(true)}>
                    <FeatherIcon icon="check" className="mr-10" />
                  </Button>
                </Tooltip>
              </div>
              <div>
                <Tooltip title="Rejeté" showArrow={false}>
                  <Button ghost onClick={() => approveSchoolStatus(false)}>
                    <FeatherIcon icon="x" className="mr-10" />
                  </Button>
                </Tooltip>
              </div>
            </>
          )}
        </>
      )}
    </StatusContainer>
  );
};

export default Status;
