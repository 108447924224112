import { createSlice } from '@reduxjs/toolkit';
import { createServiceTypes } from './serviceTypeAsyncThunk';

const initialState = {
  servicesData: [],
  isLoading: false,
  isSuccess: false,
  errorMessage: null,
};

export const serviceTypeSlice = createSlice({
  name: 'create-service-type',
  initialState,
  extraReducers: {
    [createServiceTypes.pending]: state => {
      state.isLoading = true;
    },
    [createServiceTypes.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.servicesData.push(action.payload);
    },
    [createServiceTypes.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = action.payload;
    },
  },
});

export const { serviceType } = serviceTypeSlice.actions;

export default serviceTypeSlice.reducer;
