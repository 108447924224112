import { createSlice } from '@reduxjs/toolkit';
import { createVehiculeType } from './vehiculeTypeAsyncThunk';

const initialState = {
  vehiculeData: [],
  isLoading: false,
  isSuccess: false,
  errorMessage: null,
};

export const VehiculeTypeSlice = createSlice({
  name: 'vehicule-type',
  initialState,
  extraReducers: {
    [createVehiculeType.pending]: state => {
      state.isLoading = true;
    },
    [createVehiculeType.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.vehiculeData.push(action.payload);
    },
    [createVehiculeType.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = action.payload;
    },
  },
});

export default VehiculeTypeSlice.reducer;
