import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, PageHeader, Tooltip } from 'antd';

import { setAdminListModal } from 'redux/slices/adminList/adminListSlice';
import { AdminListHeaderContainer } from '../styled';

const AdminListHeader = () => {
  const dispatch = useDispatch();

  const admins = useSelector(state => state.adminList.data);

  const isAccountLimitReached = admins.length < 3;

  const onOpenEditModal = () =>
    dispatch(setAdminListModal({ modalRef: 'add-edit-admin', editMode: false }));

  return (
    <AdminListHeaderContainer>
      <PageHeader title="Liste des admins" className="p-0 m-0" />

      <Tooltip
        title={
          isAccountLimitReached ? null : 'Vous avez atteint le nombre maximum de comptes autorisés'
        }
      >
        <Button onClick={onOpenEditModal} disabled={!isAccountLimitReached}>
          <i className="icon-add mr-10" />
          Ajouter
        </Button>
      </Tooltip>
    </AdminListHeaderContainer>
  );
};

export default AdminListHeader;
