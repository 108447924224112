import React from 'react';

import { Button } from 'antd';
import { PageHeader } from 'components/page-headers/page-headers';

import SearchInput from 'components/inputs/SearchInput';

import { TablePageHeaderContainer } from './styled';

const TablePageHeader = ({
  totalPerPage,
  total,
  title,
  onButtonClick,
  result,
  totalShownItems,
  placeholder,
  otherInputsAfterSearchInput,
  searchInputValue,
  searchInputOnChange,
  showButton,
  buttonText,
}) => {
  return (
    <TablePageHeaderContainer>
      <div className="d-flex align-items-center">
        <PageHeader title={title} className="p-0 mr-0" />

        <p className="table-result">
          {result || '---'} : {totalPerPage || '---'} - {totalShownItems || '---'} /{' '}
          {total || '---'}
        </p>
      </div>

      <div className="buttons-container">
        <SearchInput
          placeholder={placeholder}
          value={searchInputValue}
          onChange={searchInputOnChange}
        />
        {otherInputsAfterSearchInput}
        {showButton && (
          <Button onClick={onButtonClick}>
            <i className="icon-add mr-10" />
            {buttonText}
          </Button>
        )}
      </div>
    </TablePageHeaderContainer>
  );
};

export default TablePageHeader;
