import React from 'react';
import { useSelector } from 'react-redux';

import AdminAction from './AdminActions';
import { Table } from 'antd';
import { AdminItemsContainer } from '../styled';
import { Cards } from 'components/cards/frame/cards-frame';

const AdminList = () => {
  const admins = useSelector(state => state.adminList.data);

  const dataSource = admins?.map((tableRow, index) => {
    const { key = index, id, email } = tableRow;

    return {
      key,
      id,
      email: email || '---',
      actions: <AdminAction email={email} id={id} />,
    };
  });

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'admin.id',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'admin.id',
    },
  ];

  return (
    <AdminItemsContainer>
      <Cards headless>
        <Table
          className="table-responsive admins_list"
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
      </Cards>
    </AdminItemsContainer>
  );
};

export default AdminList;
